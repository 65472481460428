$(document).ready(function () {
    //init
    common();
    navJump();
    goToTop();
    question();
    loadGoogleFont();
    //index
    bannerSlider();
    //product
    // productDetailSlider();
    // productSharing();

    //blog
    blogSharing();
});


function bannerSlider() {
    // Pace.on('done', function () {
    var bSlider = null;
    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {

        if ($bannerSlider.find('.ms-view').length == 0) {

            setMasterSliderImageOnScreen($bannerSlider);

            try {
                bSlider = new MasterSlider();

                bSlider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                bSlider.control('bullets',{
                    autohide:false
                });

                bSlider.control('circletimer', {
                    autohide: false,
                    overVideo: true,
                    color: '#FFFFFF',
                    radius: 4,
                    stroke: 9
                });

                bSlider.control('arrows', {
                    autohide: true
                });
                bSlider.setup('bannerSlider', {
                    width: 1920,
                    height: 1146,
                    autoHeight: true,
                    // minHeight:400,
                    space: 0,
                    start: 1,
                    grabCursor: true,
                    swipe: true,
                    mouse: true,
                    keyboard: false,
                    // layout: "partialview",
                    wheel: false,
                    autoplay: true,
                    instantStartLayers: false,
                    mobileBGVideo: false,
                    loop: true,
                    shuffle: false,
                    preload: 0,
                    // autoHeight: true,
                    // smoothHeight: true,
                    endPause: false,
                    overPause: true,
                    // fillMode: "fill",
                    centerControls: true,
                    startOnAppear: true,
                    layersMode: "center",
                    autofillTarget: "",
                    hideLayers: false,
                    fullscreenMargin: 0,
                    speed: 30,
                    dir: "h",
                    parallaxMode: 'swipe',
                    view: "fadeBasic"
                });
                $('.master-skeleton-loader').remove();
                //這裡不觸發好像出來很慢
                $(window).resize(function(){
                    setMasterSliderImageOnScreen($bannerSlider);

                });
            } catch (err) {
                console.log(err);
                removeErrorMasterSliderContainer($bannerSlider);
            }
        }


    }
    // });
}

function navJump() {

    //選單跳段落
    //注意menu element id
    $('.header .menu-nav a').click(function () {

        console.log('click menu link');

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash);
        }
      
    });

    //處理編輯器要跳段落
    // $('.editor a').click(function () {
    //     var $anchor = $(this);
    //     console.log('click editor internal link');
    //     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {

    //         if ($anchor.attr('href').indexOf('http') < 0) {
    //             //不是完整連結才要跳
    //             jumpSection(this.hash);
    //             // return false;
    //         }

    //     }
    // });

    //關閉手機選單
    // $('body').click(function (e) {
    //     // only do this if navigation is visible, otherwise you see jump in navigation while collapse() is called 
    //     if ($('.navbar-collapse').is(':visible') && $('.navbar-toggle').is(':visible')) {
    //         //新增判斷，如果點擊的元素不在下拉子選單內，就關閉選單
    //         if ($(e.target).is('a:not(".dropdown-toggle")')) {
    //             $('.navbar-collapse').collapse('toggle');
    //         }

    //     }
    // });

    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:' + newjumpHash);
        Pace.on('done', function () {
            jumpSection(newjumpHash);
        });
    }


}
//解決沒有要用按鈕客戶的產品在mobile會異常的問題
//不變更html
function productBoxLink() {
    var $productOverlay = $('.product-overlay');
    $productOverlay.click(function () {
        var $overlay = $(this);
        var url = $overlay.find('a').attr('href');
        window.location.href = url;
    });
}

function productDetailSlider() {
    // Pace.on('done', function () {
    var bSlider = null;
    var $productSlider = $('#productSlider');
    if ($productSlider.length > 0) {
        if ($productSlider.find('.ms-view').length == 0) {
            try {

                bSlider = new MasterSlider();

                bSlider.control(
                    'thumblist', {
                        autohide: false,
                        overVideo: true,
                        dir: 'h',
                        speed: 17,
                        inset: false,
                        arrows: false,
                        hover: false,
                        customClass: '',
                        align: 'bottom',
                        type: 'thumbs',
                        margin: 5,
                        width: 100,
                        height: 100,
                        space: 5
                    });

                bSlider.setup('productSlider', {
                    width: 500,
                    height: 500,
                    space: 5,
                    margin: 5,
                    view: 'fadeBasic'
                });

            } catch (err) {
                console.log(err);
                removeErrorMasterSliderContainer($productSlider);
            }
        }


    }
    // });
}


function goToTop() {
    var $scrollToTop = $('.scrollToTop');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    var $rootElemHtmlBody = $('html, body');
    $scrollToTop.click(function () {
        $rootElemHtmlBody.animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}


function question() {

    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $('html, body').animate({
                scrollTop: $q4Elem.offset().top
            }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function (e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer =
                $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right')
                .addClass('fa-caret-down');
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function productSharing() {
    initJsSocial($('#productSharing'));
}

// function activitySharing() {
//     initJsSocial($('#activitySharing'));
// }

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook", "linkedin"]
        });
    }
}

// customer
function resetJsSocial() {
    jsSocials.shares.facebook = {
        label: "Like",
        // logo: "fa fa-facebook",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/facebook.png',

        shareUrl: "https://facebook.com/sharer/sharer.php?u={url}",
        countUrl: "https://graph.facebook.com/?id={url}",
        getCount: function (data) {
            return data.share && data.share.share_count || 0;
        }

    };

    jsSocials.shares.googleplus = {

        label: "+1",
        // logo: "fa fa-google",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/google-plus.png',
        shareUrl: "https://plus.google.com/share?url={url}",
        countUrl: ""

    };

    jsSocials.shares.linkedin = {
        label: "Share",
        // logo: "fa fa-linkedin",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/linkedin.png',

        shareUrl: "https://www.linkedin.com/shareArticle?mini=true&url={url}",
        countUrl: "https://www.linkedin.com/countserv/count/share?format=jsonp&url={url}&callback=?",
        getCount: function (data) {
            return data.count;
        }
    };

    jsSocials.shares.twitter = {
        logo: MYAPP.endpoint + 'build/fufann/images/icons/twitter.png',
        label: "Tweet",
        // logo: "fa fa-twitter",
        shareUrl: "https://twitter.com/share?url={url}&text={text}&via={via}&hashtags={hashtags}",
        countUrl: "https://cdn.api.twitter.com/1/urls/count.json?url={url}&callback=?",
        getCount: function (data) {
            return data.count;
        }
    };

}


function owlSlider($owlSlider, items, slideBy, responsive) {
    console.log('owlSlider');
    var opts = {
        autoplay: 1000,
        loop: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        margin: 10,
        autoWidth: true,
        stagePadding: 50,
        margin: 10,
        items: items,
        dots: true,
        nav: true,
        // lazyLoad: true,
        slideBy: slideBy
    }
    if (typeof (responsive) != 'undefined') {
        opts.responsive = responsive;
    }
    $owlSlider.owlCarousel(opts);
    var $owlImages = $owlSlider.find('img');
    $owlImages.on('inview', function (event, isInView) {
        if (isInView) {
            console.log('owl img inview');
            var $img = $(this);
            // console.log($img);
            $img.attr('src', $img.attr('data-lazy'));
            $img.removeAttr('data-lazy');
        }
    });
}

function bgImagesLazyload() {
    // initLazyload($('#')); 
}

function lazyMap() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#homeMap'));
    });
}

function lazyYoutube() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#homeYt'));
    });
}

//第三方iniview載入機制
function initViewLazyOfIframe($targetElem) {
    var isEnable = false;
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {
            if (isInView) {
                if (!isEnable) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    isEnable = true;
                }
            }
        });
    }
}

function lazyYoutube() {
    initLazyLoadOfYoutube($('#homeYt'));
}

function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: [
                    'Montserrat:100,300,400&display=swap',
                    'Noto Sans TC:100,300,400,600,500,700,900&display=swap'
                ]
            }
        });
    });
}