$(document).ready(function () {
    theme();
    owls();
    // appWidgetFeed($('.section-google-reviews'));

});


function theme(){
    var $body = $('body');
    var $header = $('.header');
    var $menuNavToggle = $('.menu-nav-toggle');
    var $hamburgerToggle = $('.hamburger-toggle');
    var $dropsubBtn = $('.dropsub-btn');
    var $submenu = $('.submenu');
    var $removeMask = $('.remove-mask');

    $dropsubBtn.on('click', function(){
        console.log('下拉按鈕');
        // $submenu.slideToggle();
        // $dropsubBtn.toggleClass('active');

        var $nowDropsubBtn = $(this);
        $nowDropsubBtn.toggleClass('active');
        $nowDropsubBtn.parent().find('.submenu').slideToggle();
       
    });
    $menuNavToggle.on('click',function(){
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
    $hamburgerToggle.on('click',function(){
        console.log('hamburger click');
        $body.toggleClass('active');
        $header.toggleClass('active');
        $submenu.hide();
    });
    $removeMask.on('click',function(){
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
}

function owls() {
   
    lazyloadOwl($('.owl-research'),{
        loop:false,
        autoplay:false,
        items:1,
        margin:10,
        nav:false,
        dots:false,
        autoWidth:false,
        animateIn: 'fadeIn',
        responsiveClass:true
    },function($owl){
        //綁定research
        researchBookmark($owl);
    });
}

function researchBookmark($owl){

 

    var $bookmark = $('.research-item-bookmark');
    var $linksOfBookmark = $bookmark.find('a');

    //連結點擊
    $bookmark.find('a').click(function(){
        console.log('[researchBookmark] click');
        var $link = $(this);
        //抓元素上標的索引值
        var indexOfLink = $link.attr('data-index');
        console.log('[researchBookmark] bookmark index:'+indexOfLink);
        // console.log($owl);
        $owl.trigger('to.owl.carousel', [parseInt(indexOfLink), 300]);

    });

    //切換後來綁定bookmark狀態
    $owl.on('changed.owl.carousel', function(event) {
        console.log('owl index:'+ event.item.index);
        $linksOfBookmark.removeClass('active');
        $bookmark.find('a').eq(event.item.index).addClass('active');
    });
}

function lazyloadOwl($owl, newOpts, cb) {
    console.log('lazyloadOwl');
    if ($owl.length > 0) {
       
        var defaultOpts = {
            // loop: true,
            margin: 10,
            // responsiveClass: true,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            slideBy: 1,
           
        };

        if (typeof (newOpts) != 'undefined')
            $.extend(true, defaultOpts, newOpts);

        $owl.owlCarousel(defaultOpts);
       
        owlSliderEnableLazyload($owl);

        if (typeof (cb) != 'undefined') {
            cb($owl);
        }
    }

}

function owlSliderEnableLazyload($owlSlider) {
    console.log('owlSliderEnableLazyload');
    if ($owlSlider.length > 0) {
        var isFired = false;

        var $owlImages = $owlSlider.find('img');
        $owlSlider.on('inview', function (event, isInView) {
            if (isFired == false) {
                isFired = true;
                startLoadImages($owlImages);
            }
        });
    }

}


function appWidgetFeed($initLoadElemWrapper) {
    if($initLoadElemWrapper.length > 0){
        var isEnable = false;
        $initLoadElemWrapper.on('inview', function (event, isInView) {
            if(isInView && isEnable == false){
                isEnable = true;
                var appWidgets = document.createElement("script");
                appWidgets.async = true;
                if(window.MYAPP.hasOwnProperty('apps_widget_endpoint')){
                    appWidgets.src = window.MYAPP.apps_widget_endpoint; 
                    $("head").append(appWidgets);
                }
            }
        });
    }
}